<template>
    <div>
        <div v-if="displayConsent" class="aspect-ratio-box">
            <div class="aspect-ratio-box-inside">
                <div class="aspect-content">
                    <div class="p-12 text-center">
                        <h3 class="max-w-xl md:leading-snug">
                            Dieses Video kann aufgrund Ihrer Datenschutzeinstellungen nicht
                            angezeigt werden
                        </h3>
                        <a
                            class="inline-block mb-4 text-white bg-orange-500 hover:bg-orange-400 hover:text-black md:mt-12 btn"
                            href="#"
                            @click.prevent="acceptConsents"
                        >{{ videoProviderName }}-Cookies akzeptieren und Video anzeigen</a>
                        <a href="#uc-info-modal-show" class="block">mehr Informationen</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        videoProvider: {
            type: String,
            required: true,
            default: 'asset',
        },
    },
    data() {
        return {
            youtubeConsentAccepted: false,
            vimeoConsentAccepted: false,
            youtubeCookiesConsentAccepted: false,
        }
    },
    computed: {
        displayConsent() {
            if (this.videoProvider == 'asset') return false
            if (this.videoProvider == 'vimeo' && this.vimeoConsentAccepted) return false
            if (this.videoProvider == 'youtube' && this.youtubeConsentAccepted) return false
            return true
        },
        videoProviderName() {
            return this.videoProvider.charAt(0).toUpperCase() + this.videoProvider.slice(1)
        },
    },
    mounted() {
        window.addEventListener('onConsentStatusChange', (event) => {
            if (event.data.event == 'consent_changed') {
                if ('YouTube Video' in event.data)
                    this.youtubeConsentAccepted = event.data['YouTube Video']
                if ('Youtube Cookies (ytimg)' in event.data)
                    this.youtubeCookiesConsentAccepted = event.data['Youtube Cookies (ytimg)']
                if ('Vimeo' in event.data) this.vimeoConsentAccepted = event.data['Vimeo']
            }
        })

        window.addEventListener('onConsentStatusChange', (event) => {
            if (event.data.event == 'consents_initialized') {
                // youtube
                this.youtubeConsentAccepted = window.usercentrics.getConsents(
                    'BJz7qNsdj-7'
                ).consentStatus

                // yotube cookies (ytimg)
                this.youtubeCookiesConsentAccepted = window.usercentrics.getConsents(
                    'r1ejcEi_jZ7'
                ).consentStatus

                // vimeo
                this.vimeoConsentAccepted = window.usercentrics.getConsents(
                    'HyEX5Nidi-m'
                ).consentStatus
            }
        })
    },
    methods: {
        acceptConsents() {
            if (this.videoProvider == 'youtube') {
                // youtube video
                window.usercentrics.updateConsents([{ templateId: 'BJz7qNsdj-7', status: true }])

                // youtube cookies (ytimg)
                window.usercentrics.updateConsents([{ templateId: 'r1ejcEi_jZ7', status: true }])
            }
            if (this.videoProvider == 'vimeo') {
                // vimeo
                window.usercentrics.updateConsents([{ templateId: 'HyEX5Nidi-m', status: true }])
            }
        },
    },
}
</script>

<style lang="css" scoped>
.aspect-ratio-box {
    @apply bg-black relative overflow-hidden;
    height: 0;
    padding-top: 1080px / 1920px * 100%;

    .aspect-ratio-box-inside {
        @apply absolute w-full h-full;
        top: 0;
        left: 0;
        .aspect-content {
            @apply text-white h-full justify-center flex content-center items-center;
        }
    }
}
</style>
