// Import Vue and vue2-collapse
import Vue from 'vue'
import VueCollapse from 'vue2-collapse'
import Faq from '@vue/components/Faq.vue'

// Loading the plugin into the Vue.
Vue.use(VueCollapse)

Vue.component('faq', Faq)

