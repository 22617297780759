<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    mounted() {},
}
</script>

<style lang="css">
.vc-collapse {
    @apply my-8 rounded bg-gray-100 p-8;
    .header {
        @apply relative cursor-pointer rounded pr-12 text-xl font-medium text-gray-600;

        .icon {
            transition: all 0.3s ease-in-out;
            @apply absolute right-0 top-0;
            svg {
                @apply h-4 w-4 fill-current text-mint;
            }

            &.open {
                transform: rotate(90deg);
            }
        }
    }

    .v-collapse-content {
        @apply prose max-w-none overflow-hidden pr-12 prose-a:text-mint prose-a:no-underline;
        max-height: 0;
        transition: max-height 0.3s ease-out;
    }

    .v-collapse-content-end {
        transition: max-height 0.3s ease-in;
        max-height: 500px;
    }
}
</style>
