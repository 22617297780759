<template>
    <div>

    </div>
</template>

<script>

export default {
    components: {}
}
</script>

<style scoped>
button {
    @apply text-blue-400;
}

#back-to-top {
    @apply text-blue-400 fill-current h-10 w-10;
}
</style>
