<template>
  <div class="videoplayer">
    <vue-plyr ref="player" :options="playerOptions">
      <slot></slot>
    </vue-plyr>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import plyrIcons from '~/svg/plyr.svg'

export default {
  components: {},
  props: {
    muted: {
      type: Boolean,
      required: false,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      progressInPercent: 0,
      progressStepsToLog: [10, 15, 25, 50, 75, 100]
    }
  },
  computed: {
    player() {
      return this.$refs.player.player
    },
    playerOptions() {
      const options = {
        autoplay: this.autoplay,
        muted: this.muted,
        loadSprite: true,
        iconUrl: plyrIcons,
        title: '',
        playsinline: true,
        volume: 0.5,
        controls: ['play', 'play-large', 'mute', 'volume', 'progress'],
        debug: false,
        resetOnEnd: true,
        youtube: this.youtubeOptions,
        vimeo: this.vimeoOptions
      }
      return options
    },
    vimeoOptions() {
      return {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: false
      }
    },
    youtubeOptions() {
      return {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        autoplay: this.autoplay,
        listType: 'user_uploads'
      }
    }
  },
  mounted() {
    this.player.on('ready', () => {
      this.playerReady()
    })

    this.player.on('timeupdate', () => {
      this.checkProgress()
    })

    this.player.on('play', () => {})
    this.player.on('pause', () => {})
    this.player.on('volumechange', () => {})
    this.player.on('ended', () => {})
  },

  methods: {
    sendProgressEvent(progressInPercent) {
      if (typeof dataLayer !== 'undefined') {
        let event = {
          event: 'video',
          eventCategory: 'Video',
          eventAction: `Fortschritt ${progressInPercent}%`,
          eventLabel: `Video: ${this.title}`,
          eventValue: progressInPercent
        }

        // eslint-disable-next-line
        dataLayer.push(event)
      }
    },
    getRoundedPercentage(number) {
      return Math.round(number / 5) * 5
    },
    checkProgress: throttle(function() {
      let progressInPercent = this.getRoundedPercentage(
        (this.player.currentTime / this.player.duration) * 100
      )
      if (this.progressStepsToLog.includes(progressInPercent)) {
        if (this.progressInPercent !== progressInPercent) {
          this.progressInPercent = progressInPercent
          this.sendProgressEvent(progressInPercent)
        }
      }
    }, 1000),
    playerReady() {
      this.player.muted = this.muted
      if (this.autoplay) {
        this.player.play()
      }
    }
  }
}
</script>

<style lang="css">
.videoplayer {
  .plyr {
    .plyr__video-wrapper--fixed-ratio {
      min-height: 1px !important;
    }
  }
}
</style>
