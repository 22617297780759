// import function to register Swiper custom elements
// import { register } from 'swiper/element/bundle'
// register Swiper custom elements
// register()

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const swiper1 = new Swiper('.facts .swiper', {
    autoHeight: false,
    loop: true,
    pagination: {
        el: '.facts .swiper-pagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.facts .swiper-button-next',
        prevEl: '.facts .swiper-button-prev',
    },
    slidesPerView: 1,
    breakpoints: {
        // when window width is >= 320px
        576: {
            slidesPerView: 2,
            spaceBetween: 25,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 25,
        },
    },
    // configure Swiper to use modules
    modules: [Navigation, Pagination],
})

const swiper2 = new Swiper('.statements .swiper', {
    loop: true,
    slidesPerView: 1,
    modules: [Navigation, Pagination],
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.statements .swiper-button-next',
        prevEl: '.statements .swiper-button-prev',
    },
})

const swiper3 = new Swiper('.partner .swiper', {
    loop: true,
    slidesPerView: 1,
    modules: [Navigation, Pagination],
    pagination: {
        el: '.partner .swiper-pagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.partner .swiper-button-next',
        prevEl: '.partner .swiper-button-prev',
    },
})
