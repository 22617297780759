import Vue from 'vue'
//import Store from '@/store/index' //requires promise polyfil

//Vue.config.silent = true
import './modules/vue-scrollto'
import './modules/vue-scroll-to-top'
import './modules/vue-collapse'
import './modules/vue-plyr'
import './modules/own-components'

new Vue({
    el: '#app',
    //store: Store,

    data: function () {
        return {
            mainMenuOpen: false,
        }
    },

    methods: {
        toggleMainMenu() {
            this.mainMenuOpen = !this.mainMenuOpen
        },
    },
})
